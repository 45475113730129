export function querySearchToObject(querySearch) {
  const result = {};

  querySearch
    .split('&')
    .map(el => {
      const key = el.split('=')[0].replace('?', '');
      const val = el.split('=')[1].replace('%40', '@');

      result[key]= val;
    });

  return result;
}
